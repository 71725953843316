import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactButton from "../components/ContactButton"

const AopPage = () => (
  <Layout>
    <SEO 
      title="Ambassador of Possibilities Program - Pluralistic Networks"
      description="An Ambassador of Possibilities™ does more than make sales; they open up new possibilities for their clients. Learn how to become one today."
    />

    <div class=" maxWidth pagePadding" style={{ marginTop: "78px" }}>
      <div class="centered">
        <span class="pageTitle2">Ambassador</span>
        <span class=" pageSub2"> of Possibilities</span>
      </div>

      <div style={{ marginTop: "56px" }} class=" sectionTitle">
        A New Orientation to Sales
      </div>
      <div style={{ marginTop: "24px" }} class="bigText">
        We live in a world of endless possibilities, although it doesn’t always
        feel that way. Too often do we fail to see all of the emerging
        possibilities around us until it is too late.
        <br />
        <br />
        An Ambassador of Possibilities™ is someone who is able to expand their
        ability to discover new possibilities for themselves and others. He or
        she actively cultivates his or her mood to be in a mood of exploration
        regarding the future; he or she is someone who is engaged in
        conversations about the future, and brings his or her clients to
        participate in those conversations.
        <br />
        <br />
        How can we cultivate ourselves as a source of possibilities and offers
        for others? This question is not only relevant for salespeople, but for
        any person who wants to make successful offers that add value to others.
        In essence, selling is not just about closing on one transaction; it is
        about opening new possibilities for people in a relationship of care.
        <br />
        <br />
        To become an Ambassador of Possibilities™, we must develop certain
        fundamental skills: The ability to listen expansively for concerns and
        possibilities, the ability to build trustworthy relationships, the
        ability to make and listen to assessments on a timely basis, the ability
        to navigate away from moods that restrict us and/or our clients, and to
        cultivate moods that open possibilities instead. In contrast to a person
        that is oriented to deal with immediate breakdowns or to solve existing
        problems, an Ambassador of Possibilities is distinguished by a certain
        kind of sensibility—a sensibility for the emerging future and how this
        shows up as new possibilities for their clients.
      </div>
      <div
        style={{
          marginTop: "56px",
          marginBottom: "5px",
        }}
        class="topLine"
      >
        &nbsp;
      </div>
      <div
        style={{
          marginBottom: "56px",
        }}
        class="bottomLine"
      >
        &nbsp;
      </div>
      <div>
        <span class="pageSub2">The </span>
        <span class=" pageTitle2"> AoP </span>
        <span class=" pageSub2">solution</span>
      </div>
      <div style={{ marginTop: "46px" }} class="bigText">
        Learning these skills takes time and immersive practice, along with
        guided reflection and observation. Just like you can’t learn to play an
        instrument by memorizing an instruction book, these skills cannot be
        learned simply by following rules, instructions or techniques, or by
        simply attending a workshop. The Ambassador of Possibilities program is
        designed to develop these skills over a course of 5 months, with weekly
        practice and discussions.
      </div>
    </div>
    <div style={{ textAlign: "center", marginBottom: "30px" }}>
      <ContactButton></ContactButton>
    </div>
    <div style={{ marginTop: "140px" }} class="topLine">
      &nbsp;
    </div>
  </Layout>
)

export default AopPage
